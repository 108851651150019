import { EmailIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Heading,
  Link,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { graphql, navigate, PageProps } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import BrandingButton from '../components/BrandingButton';
import InstaLink from '../components/InstaLink';
import { Layout } from '../components/Layout';
import Section from '../components/Section';
import { COPY } from '../sections/IndexPage';

const BODY_LINES = [
  'Hi Bring Your Bar Home! Can we be added to the list?',
  'name of bar:',
  'postcode:',
  'listing description (just a couple of sentences):',
  'collection (Y/N):',
  'delivery (Y/N):',
  'delivery radius (miles):',
  'delivery/collection info (just a couple of sentences):',
  'image of cocktail offering:',
  'happy for us to use your image on our instagram? (Y/N)',
];
const SUBJECT = 'Please add me to the list';
const EMAIL = 'BringYourBarHome@gmail.com';

const ABOUT_US = [
  `Bring Your Bar Home is the UK's first guide to cocktail takeaway and delivery personalised to your location.`,
  `The idea was born out of two things:
a love for great cocktails, and a love for the hospitality industry.
Some cocktails are available for local delivery and collection, others are delivering nationwide.
Wherever you are based, why not support hospitality after a challenging year and enjoy a cocktail or two.
We’ve made them easy to find!`,
];

interface Data {
  file: {
    childImageSharp: {
      fixed: typeof Img['fixed'];
    };
  };
}

export default function About({ data }: PageProps<Data>): JSX.Element {
  return (
    <Layout>
      <Section
        p={0}
        marginBottom={40}
        minWidth={['100%', 200]}
        maxWidth="440px"
      >
        <Stack
          w="full"
          paddingTop={[10]}
          px={4}
          spacing={20}
          alignItems="center"
        >
          <BrandingButton
            onClick={() => {
              navigate('/');
            }}
          >
            {COPY.landing.title.map((s) => (
              <Box as="span" display="block" key={s}>
                {s}
              </Box>
            ))}
          </BrandingButton>
          <VStack spacing={6}>
            <Box
              w="full"
              h="full"
              as={Img}
              fixed={data.file.childImageSharp.fixed}
              objectFit="cover"
              objectPosition="50% 50%"
              alt="image of the app"
              rounded="lg"
            />

            <Heading size="md" color="gray.800">
              About Us
            </Heading>
            <Stack spacing={4}>
              {ABOUT_US.map((text) => (
                <Text
                  key={text}
                  fontSize="sm"
                  color="gray.800"
                  textAlign="justify"
                >
                  {text}
                </Text>
              ))}
            </Stack>
          </VStack>

          <VStack spacing={6} alignItems="center">
            <Heading size="md" color="gray.800">
              List Your Cocktails
            </Heading>
            <VStack spacing={2}>
              <Text fontSize="sm" color="gray.800">
                Drop us an email to be added to the list!
              </Text>
              <Button
                leftIcon={<EmailIcon />}
                colorScheme="teal"
                variant="outline"
                as={Link}
                isExternal
                href={`mailto:${EMAIL}?subject=${SUBJECT}&body=${BODY_LINES.join(
                  '%0D%0A%0D%0A',
                )}`}
                onClick={() => {
                  window?.plausible?.('SEND_EMAIL', {
                    callback: () => console.info('Sent SEND_EMAIL event'),
                  });
                }}
              >
                Email us now
              </Button>
              <Divider py={4} my={6} orientation="horizontal" />
              <InstaLink
                justifySelf="flex-end"
                position={['static', null, 'absolute']}
                mr={[0, null, 4, 10]}
                top={10}
                right={0}
              />
            </VStack>
          </VStack>
        </Stack>
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query {
    file(relativePath: { eq: "BYBH-plant-and-phone.jpg" }) {
      childImageSharp {
        # i.e. the max width of your container is 700 pixels.
        #
        # Other options include maxHeight (set both maxWidth and maxHeight to crop),
        # grayscale, duotone, rotate, etc.
        fixed(width: 300, height: 300) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;
